import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_MultiAccountSelector = _resolveComponent("MultiAccountSelector")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, {
    class: "login-account-selection",
    title: _ctx.t('Log in')
  }, {
    subtitle: _withCtx(() => [
      _createVNode(_component_a_typography_paragraph, { class: "whitespace-normal" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Create a new account?")) + " ", 1),
          _createVNode(_component_router_link, {
            to: _ctx.routePaths.register
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Sign up")), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_typography_paragraph, { class: "whitespace-normal" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("You signed up with these accounts earlier")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_paragraph, { class: "uppercase mb-2 small" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Select the account")), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MultiAccountSelector, {
          class: "login-account-selection__account-selector",
          mode: _ctx.MultiAccountSelectorMode.login_selection,
          onOnAccountSelect: _ctx.handleOnAccountSelect
        }, null, 8, ["mode", "onOnAccountSelect"])
      ]),
      _createVNode(_component_a_button, {
        block: "",
        onClick: _ctx.handleLoginWithAnotherAccount
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Log in with another account")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_SubmitButton, {
        class: "mt-24",
        onClick: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Log in")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["title"]))
}