import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginAccountSelection = _resolveComponent("LoginAccountSelection")!
  const _component_LoginStep2 = _resolveComponent("LoginStep2")!
  const _component_LoginStep1 = _resolveComponent("LoginStep1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authAction === _ctx.AuthActionEnum.SELECT)
      ? (_openBlock(), _createBlock(_component_LoginAccountSelection, { key: 0 }))
      : (_ctx.loginState.twoFactorRequired)
        ? (_openBlock(), _createBlock(_component_LoginStep2, {
            key: 1,
            onOnLogoutSuccess: _ctx.handleResetLoginState
          }, null, 8, ["onOnLogoutSuccess"]))
        : (_openBlock(), _createBlock(_component_LoginStep1, {
            key: 2,
            onLoginSuccess: _ctx.loginSuccess,
            onOnBack: _ctx.handleLoginStep1Back
          }, null, 8, ["onLoginSuccess", "onOnBack"]))
  ]))
}