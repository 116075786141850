
import { CheckboxOptionType } from "ant-design-vue/lib/checkbox/Group";
import { defineComponent, PropType } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    checkLabel: {
      type: String,
    },
    value: {
      type: [Boolean, Array],
    },
    options: {
      type: Array as PropType<(string | CheckboxOptionType)[]>,
    },
  },
  setup(props) {
    const checkboxValue = useVModel(props, "value");

    return { checkboxValue };
  },
  components: {
    FormItem,
  },
});
