
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import { computed, defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { makeStakingIdValidator } from "@/shared/utils/validators/adaValidators";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputPassword from "@/shared/components/Forms/InputPassword.vue";
import routePaths from "@/web/router/routePaths";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { SUPPORTED_EXTERNAL_WALLETS_OPTIONS } from "@/shared/utils/cardano";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import isEmpty from "lodash/isEmpty";

export default defineComponent({
  emits: ["loginSuccess", "on-back"],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const { accountList } = useAccount();
    const { handleLogin, loginLoading, handleLoginExternal, walletLoading } =
      useAuthentication();

    const formLoginState = reactive({
      stakingId: "",
      password: "",
      doNotExpireSession: true,
    });

    const handleSubmit = async () => {
      try {
        await handleLogin({
          username: formLoginState.stakingId,
          password: formLoginState.password,
          doNotExpireSession: formLoginState.doNotExpireSession,
        });
        emit("loginSuccess");
        registrationSimpleStore.stakingId = formLoginState.stakingId;
      } catch (error) {
        console.log(error);
      }
    };

    //  Separated login with other wallet, separate API later
    const handleLoginThirdParty = async (walletName) => {
      try {
        await handleLoginExternal(
          walletName,
          formLoginState.doNotExpireSession
        );
        emit("loginSuccess");
      } catch (error) {
        console.log("handleSubmitThirdParty:error", error);
      }
    };

    return {
      t,
      loginLoading,
      walletLoading,
      formLoginState,
      handleSubmit,
      handleLoginThirdParty,
      SUPPORTED_EXTERNAL_WALLETS_OPTIONS,
      routePaths,
      showBackButton: computed(() => !isEmpty(accountList.value)),
    };
  },
  components: {
    FullPageCardLayout,
    FormGroup,
    InputText,
    InputPassword,
    SubmitButton,
    InputCheckbox,
  },
  methods: {
    makeRequiredRule,
    makeStakingIdValidator,
  },
});
