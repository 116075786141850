import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "login-step-1__login-methods" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, {
    class: "login-step-1",
    title: _ctx.t('Log in')
  }, {
    subtitle: _withCtx(() => [
      _createVNode(_component_a_typography_paragraph, { class: "whitespace-normal" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Don’t have an account?")) + " ", 1),
          _createVNode(_component_router_link, {
            to: _ctx.routePaths.register
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Sign up")), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    "header-right": _withCtx(() => [
      (_ctx.showBackButton)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "link",
            class: "p-0",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Back")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        class: "login-step-1__form",
        model: _ctx.formLoginState,
        loading: _ctx.loginLoading,
        disabled: !!_ctx.walletLoading,
        onFinish: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            label: _ctx.t('Staking Key Hash'),
            name: "stakingId",
            placeholder: "ba12d7cfa46e187426a72655fc26e632f...",
            value: _ctx.formLoginState.stakingId,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formLoginState.stakingId) = $event)),
            rules: [
          _ctx.makeRequiredRule(_ctx.t('Staking Key Hash is required')),
          _ctx.makeStakingIdValidator(),
        ]
          }, null, 8, ["label", "value", "rules"]),
          _createVNode(_component_InputPassword, {
            label: _ctx.t('Password'),
            name: "password",
            placeholder: "**********",
            value: _ctx.formLoginState.password,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formLoginState.password) = $event)),
            rules: [_ctx.makeRequiredRule(_ctx.t('Password is required'))]
          }, {
            labelEnd: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.routePaths.forgotPassword
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Forgot?")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }, 8, ["label", "value", "rules"]),
          _createVNode(_component_InputCheckbox, {
            checkLabel: _ctx.t('Keep me logged in'),
            name: "doNotExpireSession",
            value: _ctx.formLoginState.doNotExpireSession,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formLoginState.doNotExpireSession) = $event))
          }, null, 8, ["checkLabel", "value"]),
          _createVNode(_component_SubmitButton, {
            class: "mt-24",
            loading: _ctx.loginLoading,
            disabled: !!_ctx.walletLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Log in")), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      }, 8, ["model", "loading", "disabled", "onFinish"]),
      _createVNode(_component_a_divider, { class: "card-internal-divider" }),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SUPPORTED_EXTERNAL_WALLETS_OPTIONS, (app) => {
          return (_openBlock(), _createBlock(_component_a_button, {
            key: app.value,
            onClick: ($event: any) => (_ctx.handleLoginThirdParty(app.value)),
            block: "",
            disabled: !!_ctx.walletLoading && _ctx.walletLoading !== app.value,
            loading: _ctx.walletLoading === app.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Log in with {app}", { app: app.label })), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "disabled", "loading"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}