
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import routePaths from "@/web/router/routePaths";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputOtp from "@/shared/components/Forms/InputOtp.vue";
import routeNames from "@/shared/router/routeNames";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { message } from "ant-design-vue";
import { emptyLoginFormState } from "@/web/views/Login/Login.vue";
import TopHeaderLogout from "@/web/views/Register/TopHeaderLogout.vue";
import attempt from "lodash/attempt";

export default defineComponent({
  components: {
    FullPageCardLayout,
    FormGroup,
    InputOtp,
    SubmitButton,
    TopHeaderLogout,
  },
  emits: ["on-logout-success"],
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const otpInputRef = ref();
    const nextUrl = router.currentRoute.value.query?.next as string;

    const { loginState, handleLogin2faValidate, twoFactorValidateLoading } =
      useAuthentication();

    const formAuthState = reactive({
      loginOTP: "",
    });

    const handleSubmit = async () => {
      try {
        await handleLogin2faValidate({ otpCode: formAuthState.loginOTP });
        await router.push(nextUrl || { name: routeNames.home });

        message.info(t("Successfully Logged In."));
        Object.assign(loginState, emptyLoginFormState);
      } catch (error) {
        console.log(error);
        attempt(otpInputRef.value?.clearInput);
      }
    };

    return {
      t,
      otpInputRef,
      routePaths,
      formAuthState,
      handleSubmit,
      makeRequiredRule,
      twoFactorValidateLoading,
    };
  },
});
