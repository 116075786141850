
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoginStep1 from "@/web/views/Login/LoginStep1.vue";
import LoginStep2 from "@/web/views/Login/LoginStep2.vue";
import LoginAccountSelection from "@/web/views/Login/LoginAccountSelection.vue";
import routeNames from "@/shared/router/routeNames";
import { message } from "ant-design-vue";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import { UserPendingAction } from "./../../../../__generated__/globalTypes";
import { aliasRouteRegisterOnBoarding } from "@/shared/router/routePaths";
import { AuthActionEnum, AUTH_ACTIONS } from "@/web/store/authStore";

export const emptyLoginFormState = {
  user: {
    id: "",
    username: "",
    created: "",
    wallet: null,
    name: "",
    avatar: null,
    pendingAction: null,
  },
  twoFactorRequired: false,
};

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const nextUrl = router.currentRoute.value.query?.next as string;

    const { loginState, resetLoginState } = useAuthentication();

    const loginSuccess = async () => {
      // 2fa is not required
      if (!loginState.twoFactorRequired) {
        if (store.state.userInfo.pendingAction != UserPendingAction.A_) {
          //pendingAction is not equals to None
          router.push(aliasRouteRegisterOnBoarding);
        } else {
          // set isAuthenticated to true
          await store.dispatch("authUpdate", {
            isAuthenticated: true,
          });

          // go to dashboard
          router.push(nextUrl || { name: routeNames.home });
          message.info(t("Successfully Logged In."));
          Object.assign(loginState, emptyLoginFormState);
        }
      }
    };

    const handleResetLoginState = () => {
      /**
       * This is useful for multi-step login page
       */
      resetLoginState();
    };

    const handleLoginStep1Back = async () => {
      await store.dispatch(AUTH_ACTIONS.SWITCH_PREV_ACCOUNT);
      await router.push(nextUrl || { name: routeNames.home });
    };

    return {
      t,
      AuthActionEnum,
      loginState,
      loginSuccess,
      handleResetLoginState,
      handleLoginStep1Back,
      authAction: computed(() => store.state.auth.authAction),
    };
  },
  components: {
    LoginStep1,
    LoginStep2,
    LoginAccountSelection,
  },
});
