
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import routePaths from "@/web/router/routePaths";
import routeNames from "@/shared/router/routeNames";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { message } from "ant-design-vue";
import MultiAccountSelector, {
  MultiAccountSelectorMode,
} from "@/shared/components/MultiAccountSelector/MultiAccountSelector.vue";
import { useStore } from "vuex";
import { AUTH_ACTIONS } from "@/web/store/authStore";

export default defineComponent({
  emits: ["on-logout-success"],
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const nextUrl = router.currentRoute.value.query?.next as string;

    const localSelectedUser = reactive({});

    const handleOnAccountSelect = (user) => {
      Object.assign(localSelectedUser, user);
    };

    const handleSubmit = async () => {
      try {
        if (localSelectedUser["userId"]) {
          await store.dispatch(AUTH_ACTIONS.SWITCH_ACCOUNT, {
            userId: localSelectedUser["userId"],
          });
          await router.push(nextUrl || { name: routeNames.home });

          message.info(t("Successfully Logged In."));
        } else {
          console.warn("Unhandled error", localSelectedUser);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleLoginWithAnotherAccount = () => {
      store.dispatch(AUTH_ACTIONS.ADD_ACCOUNT);
    };

    return {
      t,
      MultiAccountSelectorMode,
      routePaths,
      handleOnAccountSelect,
      handleSubmit,
      handleLoginWithAnotherAccount,
    };
  },
  components: {
    FullPageCardLayout,
    SubmitButton,
    MultiAccountSelector,
  },
});
