import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeaderLogout = _resolveComponent("TopHeaderLogout")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_InputOtp = _resolveComponent("InputOtp")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, {
    class: "login-step-2",
    title: _ctx.t('Two-factor auth')
  }, {
    "header-right": _withCtx(() => [
      _createVNode(_component_TopHeaderLogout, {
        onOnLogoutSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-logout-success')))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        class: "login-step-2__form",
        model: _ctx.formAuthState,
        loading: _ctx.twoFactorValidateLoading,
        onFinish: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_typography_paragraph, { class: "whitespace-normal" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(
            "Please confirm your account by entering the six digit code from Authenticator app"
          )), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputOtp, {
            ref: "otpInputRef",
            value: _ctx.formAuthState.loginOTP,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formAuthState.loginOTP) = $event)),
            class: "login-step-2__form-otp-input",
            name: "loginOTP",
            rules: [_ctx.makeRequiredRule(_ctx.t('OTP is required'))],
            onOnComplete: _ctx.handleSubmit
          }, null, 8, ["value", "rules", "onOnComplete"]),
          _createVNode(_component_SubmitButton, {
            class: "mt-24",
            loading: _ctx.twoFactorValidateLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Verify")), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["model", "loading", "onFinish"])
    ]),
    _: 1
  }, 8, ["title"]))
}